<template>
  <div class="card we-block-card" v-bind:style="getStyle">
    <!-- Settings -->
    <div v-if="column.type" class="edit-block text-right">
      <button
        class="btn btn-primary btn-sm"
        v-on:click="isSettingMenuOpen = true"
      >
        <i class="fas fa-cog fa-sm"></i>
      </button>
    </div>
    <!-- ./Settings -->

    <!-- Type Selection -->
    <div v-if="!column.type" class="dropdown text-center m-3">
      <button
        class="btn btn-row-dropdown"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fas fa-plus fa-sm"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          v-for="dropdownItem in dropdownItems"
          v-bind:key="dropdownItem.id"
          class="dropdown-item"
          href="#"
          v-on:click.prevent="onSelectType(dropdownItem.id)"
          >{{ dropdownItem.label }}</a
        >
      </div>
    </div>
    <!-- ./Type Selection -->

    <!-- Editor -->
    <EditorColumn
      v-else-if="column.type == 'editor'"
      v-model="column.content"
    />
    <!-- ./Editor -->

    <!-- Brand -->
    <BrandColumn v-if="column.type == 'brands'" v-model="column" />
    <!-- ./Brand -->

    <!-- Product -->
    <ProductColumn v-if="column.type == 'products'" v-model="column" />
    <!-- ./Product -->

    <!-- Column Settings -->
    <WeSidebarMenu
      v-if="isSettingMenuOpen"
      title="Kolon Düzenle"
      v-on:close="isSettingMenuOpen = false"
    >
      <ColumnSettings v-model="column" />
    </WeSidebarMenu>
    <!-- ./Column Settings -->
  </div>
</template>

<script>
const ColumnSettings = () => import("./components/ColumnSettings/Index.vue");
const BrandColumn = () => import("./components/BrandColumn/Index.vue");
const ProductColumn = () => import("./components/ProductColumn/Index.vue");
const EditorColumn = () => import("./components/EditorColumn/Index.vue");

export default {
  name: "RowColumn",
  components: {
    ColumnSettings,
    BrandColumn,
    ProductColumn,
    EditorColumn,
  },
  data() {
    return {
      isSettingMenuOpen: false,
      dropdownItems: [
        {
          id: "products",
          label: "Ürün",
        },
        {
          id: "brands",
          label: "Marka",
        },
        {
          id: "editor",
          label: "Editor",
        },
      ],
    };
  },
  props: {
    columns: {
      default: () => [],
    },
    colIndex: {
      default: 0,
    },
  },
  methods: {
    onSelectType(id) {
      this.column.type = id;
    },
  },
  computed: {
    column() {
      return this.columns[this.colIndex];
    },
    getStyle() {
      const {
        width,
        backgroundColor,
        backgroundImage,
        backgroundSize,
        backgroundRepeat,
        borderWidth,
        borderStyle,
        borderColor,
        padding,
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
      } = this.column.style;

      return {
        width: `${width}%`,
        backgroundColor,
        "background-image": backgroundImage
          ? `url('${backgroundImage}')`
          : "none",
        "background-size": `${backgroundSize}%`,
        backgroundRepeat,
        "border-width": `${borderWidth}px`,
        "border-style": borderStyle,
        "border-color": borderColor,
        padding: `${padding}px`,
        "border-top-left-radius": `${borderTopLeftRadius}px`,
        "border-top-right-radius": `${borderTopRightRadius}px`,
        "border-bottom-left-radius": `${borderBottomLeftRadius}px`,
        "border-bottom-right-radius": `${borderBottomRightRadius}px`,
      };
    },
  },
};
</script>

<style lang="scss">
.we-block-card {
  transition: 400ms all;
  border-width: 2px;
  border-style: dashed;
  .edit-block {
    visibility: hidden;
  }
  &:hover {
    // border: 2px dashed rgb(212, 212, 212);
    box-shadow: 0px 1px 10px rgb(212, 212, 212);
    .edit-block {
      visibility: visible;
    }
  }
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border: none !important;
    outline: none !important;
  }
  &-setting {
    display: block;
  }
}
.btn-row-dropdown {
  padding: 5px 10px;
  color: rgb(4, 188, 4);
  border: 1px solid rgb(4, 188, 4);
  background-color: rgb(208, 255, 208);
  border-radius: 100%;
}
</style>